> footer > section {
	&.footer-top {
		background: $Fantasy;
		padding: 120px 0 80px;
		position: relative;
		@include media-breakpoint-up(xl) {
			padding: 120px 0;
		}

		.container {
			&-holder {
				.column {
					.page-block.footer-text {
						max-width: 525px;
						margin: auto;
						text-align: center;

						h2,
						h5 {
							font-weight: $font-weight-base;
						}

						h5 {
							color: $blue;
							font-size: $font-size-base * 1.125;
						}

						h2 {
							color: $teal-green;
						}

						p {
							font-size: $font-size-base_sm;
							color: $dark-slate-gray;
							font-family: $font-spline-sans;
						}
					}
				}
			}
		}

		&::before {
			background: url("/images/background01.svg");
			content: "";
			position: absolute;
			width: 100%;
			height: 60px;
			top: -5px;
			z-index: 1;
			right: 0;
			@include media-breakpoint-up(sm) {
				top: -15px;
			}
			@include media-breakpoint-up(xl) {
				top: -25px;
			}
			@media screen and (min-width: 1920px) {
				top: -35px;
			}
		}

		&::after {
			background: url("/images/footer-top.svg") no-repeat top/100%;
			content: "";
			position: absolute;
			width: 100%;
			height: 75px;
			bottom: -50px;
			z-index: 1;
			right: 0;
			@include media-breakpoint-up(sm) {
				height: 110px;
			}
			@include media-breakpoint-up(xl) {
				bottom: -25px;
				height: 135px;
			}
		}
	}

	&.footer-social-logos {
		.container {
			&-holder {
				.column {
					.page-block {
						flex-wrap: nowrap !important;
						justify-content: center;
						padding: 0;
						position: absolute;
						left: 50%;
						bottom: -10px;
						transform: translateX(-50%);
						z-index: 1;
						@include media-breakpoint-up(sm) {
							bottom: 0;
						}

						li {
							i {
								display: flex;
								justify-content: center;
								align-items: center;
								width: 66px;
								height: 66px;
								border-radius: 5px;
								font-size: $h2-font-size;
								background: $white;
								color: $light-blue;
							}

							span {
								display: none;
							}

							a {
								text-decoration: none;
							}
						}
					}
				}
			}
		}
	}

	&.footer-logos {
		padding: 0 0 50px;
		margin-top: 100px;
		background: $Fantasy;
		position: relative;

		.container {
			z-index: 2;
			position: relative;

			&-holder {
				.column {
					.footer-logolink {
						.list {
							margin-top: -10px;

							&-item {
								margin: 0 10px 30px 0;
								width: 130px;
								height: 80px;
								@include media-breakpoint-up(sm) {
									margin: 0 30px 30px 0;
								}
								@include media-breakpoint-up(lg) {
									margin: 0 30px 0 0;
								}
							}
						}
					}
				}
			}
		}

		&:after {
			content: "";
			background: url(/images/banner-bottom.svg) no-repeat top/100%;
			position: absolute;
			width: 100%;
			height: 100px;
			top: -15px;
			z-index: 1;
			right: 0;
			transform: rotate(180deg);
		}
	}

	&.footer-content {
		padding: 80px 0 120px;
		background: $blue;
		position: relative;

		&::after {
			background: url("/images/banner-bottom.svg") no-repeat top/100%;
			content: "";
			position: absolute;
			width: 100%;
			height: 100px;
			bottom: -85px;
			z-index: 1;
			right: 0;
			@include media-breakpoint-up(sm) {
				bottom: -68px;
			}
			@include media-breakpoint-up(xl) {
				bottom: -40px;
			}
			@media screen and(min-width: 1366px) {
				bottom: -48px;
			}
			@media screen and(min-width: 1680px) {
				bottom: -25px;
			}
		}

		.container {
			&-holder {
				.column {
					&.one {
						text-align: center;
						@include media-breakpoint-up(lg) {
							text-align: left;
						}

						.logo {
							&:last-of-type {
								img {
									max-width: 165px;
									height: 61px;
								}
							}
						}
					}

					&.two {
						.page-block.footer-text {
							p {
								&:nth-of-type(3) {
									margin-bottom: 24px;
								}
							}
						}
					}

					&.two,
					&.three {
						.page-block.footer-text {
							h3,
							p {
								color: $white;
								font-weight: $font-weight-base;
							}

							p {
								font-family: $font-spline-sans;
								font-size: $font-size-base_sm;
								margin-bottom: 8px;

								a {
									color: $light-blue;

									&:hover {
										opacity: 0.7;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&.footer-links {
		padding: 4vh 0;
	}

	&.footer-socket {
		padding: 1vh 0;
		background: $Fantasy;

		.container {
			&-holder {
				.column {
					.page-block.footer-link {
						.list {
							display: flex !important;
							justify-content: center;

							&-item {
								margin: 0 30px 0 0;

								a {
									font-size: $font-size-base_sm;
									font-family: $font-spline-sans;
									color: $light-blue;

									&:hover {
										opacity: 0.7;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

&.home {
	.footer {
		&-top {
			padding: 100px 0;
			background: transparent;
			@include media-breakpoint-down(md) {
				padding: 50px 0;
			}

			&::before {
				content: none;
			}
		}
	}
}

&.bundle-overview,
&.bundle-category {
	.footer {
		&-top {
			padding: 100px 0;

			&::before {
				content: none;
			}
		}
	}
}
