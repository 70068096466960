// variables loaded before loading vendor variables, used to prepare vendor variables like bootstrap
@font-face {
  font-family: "Montagu Slab";
  src: url("/fonts/MontaguSlab_24pt-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Montagu Slab";
  src: url("/fonts/MontaguSlab_24pt-Bold.ttf");
  font-weight: 700;
}
$font-montagu-slab: "Montagu Slab", sans-serif;
$font-family-base: $font-montagu-slab;

// font
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');
// $font-family-open-sans: 'Open Sans', sans-serif;
// $font-family-base: $font-family-open-sans;

// build options
$use-sticky-header: true;
$use-html-fontsize-reset: true;

// colors
$light: #BBD331;
$dark: #3d4609;
$blue: #161B4B;
$light-blue: #1B98E0;
$dark-red: #880606;
$Fantasy: #F4F3EE;
$teal-green: #0B6E4F;
$dark-slate-gray: #283747;

// theme-colors
$primary: $blue;
$secondary: $light-blue;
$tertiary: $teal-green;
$quaternary: $dark-red;

/*
$body-bg: #FFF !default;
$body-color: #000 !default;
$body-color--dark: #fff !default;
$link-color: $primary !default;
$link-color--hover: darken($link-color, 15%) !default;

// font
$font-family-base: 'Helvetica', sans-serif !default;

// typography
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-style-italic: italic;
$font-style-base: normal !default;

$headings-font-family: $font-family-base !default;
$headings-font-style: $font-style-base !default;
$headings-font-weight: $font-weight-bold !default;
$headings-color: $body-color !default;

$color-h1: $body-color !default;
$color-h2: $body-color !default;
$color-h3: $body-color !default;
$color-h4: $body-color !default;
$color-h5: $body-color !default;
$color-h6: $body-color !default;

// grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1500px,
  xxxl: 1820px
) !default;

$grid-breakpoints-small: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths-small: (
  xs: 0,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1000px,
  xxl: 1300px,
  xxxl: 1600px
) !default;

// header
$header-logo-height: 50px !default;
$header-logo-height--lg: 80px !default;
$header-logo-margin: 20px !default;

// card
$card-bg--dark: #000 !default;

// carousel
$carousel-min_height: 35vh !default;
$carousel-min_height--lg: 70vh !default;
*/
