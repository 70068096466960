.btn,
.card-btn {
    display: inline-flex;
    align-items: center;
    padding: 5px 20px;
    font-size: $font-size-base * 1.125 !important;
    font-family: $font-montagu-slab !important;
    font-weight: $font-weight-base;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba($black, 0.16);
    color: $white;
    text-decoration: none;
    transition: 0.3s;
    
    /* btn-primary */
    &.btn-primary {
        background: $blue;
        border-color: $blue;
        color: $white;
        &:hover {
            background: $white;
            border-color: $blue;
            color: $blue;
        }
    }

	/* btn-outline-primary */
    &.btn-outline-primary {
        background-color: transparent;
        border-color: $blue;
        color: $blue !important;
        &:hover {
            background-color: $blue;
            color: $white !important;
        }
    }
    /* btn-outline-primary-transparent */ 
    &.btn-outline-primary-transparent {
        background-color: transparent;
        border-color: $white;
        color: $white !important;
        &:hover {
       }
    }
    /* btn-secondary */
    &.btn-secondary {
        background-color: $light-blue;
        border-color: $light-blue;
        color: $white !important;
        &:hover {
            background-color: $white;
            border-color: $light-blue;
            color: $light-blue !important;
        }
    }
    /* btn-secondary-transparent */
    &.btn-secondary-transparent {
        background-color: transparent;
        border-color: transparent;
        color: $white;
        font-size: $font-size-base_sm !important;
        font-family: $font-spline-sans !important;
        font-weight: $font-weight-bold !important;
        box-shadow: none;
        text-decoration: underline;
        padding: 0;
        &:hover {
            color: $light-blue !important;
        }
    }
    /* btn-outline-secondary */
    &.btn-outline-secondary {
        background-color: transparent;
        border-color: $light-blue;
         &:hover {
             background-color: $light-blue;
             color: $white !important;
        }
    }

}

/*  btn-back
    ========================================================================== */
    .btn-back {
        display: inline-block;
        font-size: 15px;
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;
        &::before {
            display: inline-block;
        }		
    }
  
/*  btn-down
    ========================================================================== */
    .btn-down {
        @extend .btn;
        @extend .btn-primary;
    }
  