/* 	bg-light/bg-dark
    ========================================================================== */
section.bg-light,
section.bg-dark {
	margin: 0;
	padding: 6vh 0;
}

/* 	lead-section
    ========================================================================== */
.lead-section {
	padding: 100px 0 120px;
	// margin-top: 35px;
	background: $Fantasy;
	position: relative;
	// &::before {
	// 	background: url("/images/banner-bottom.svg") no-repeat top/100%;
	// 	content: "";
	// 	position: absolute;
	// 	width: 100%;
	// 	height: 60px;
	// 	top: -20px;
	// 	z-index: 1;
	// 	right: 0;
	// 	@media screen and (max-width: 320px) {
	// 		top: -15px;
	// 	}
	// }
	@include media-breakpoint-up(sm) {
		padding: 100px 0 200px;
		margin-top: 0;
		background: transparent;
		&::before {
			content: none;
		}
	}
	.container-one-column {
		.container-holder {
			max-width: 810px;
		}
	}
	.container-two-columns {
		.container-holder {
			.column {
				&.one {
					.title {
						h5 {
							font-size: $font-size-base * 1.125 !important;
							color: $blue;
						}
						h1 {
							color: $teal-green;
						}
					}
					.wysiwyg {
						p {
							font-size: $font-size-base_sm;
							font-family: $font-spline-sans;
							color: $dark-slate-gray;
							margin-top: 20px;
						}
					}
				}
				&.two {
					z-index: 1;
				}
			}
		}
	}

	/* bundles */
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
		.back-to-overview-link {
			.btn-back {
				display: inline-block;
				font-size: $font-size-base_sm !important;
				font-weight: $font-weight-base;
				background: transparent;
				border: none;
				box-shadow: none;
				color: $light-blue;
				padding: 0 0 20px;
				text-transform: capitalize;
				&::before {
					content: none;
				}
				&::after {
					display: block;
					content: "";
					border-bottom: 2px solid $light-blue;
					width: 80px;
				}
			}
		}
	}
	.info {
		.subtitle {
			h5 {
				font-size: $font-size-base * 1.125 !important;
				color: $blue;
			}
		}
		.title {
			h1 {
				color: $teal-green;
			}
		}
		.wysiwyg {
			p {
				font-size: $font-size-base_sm;
				font-family: $font-spline-sans;
				color: $dark-slate-gray;
				margin-top: 20px;
			}
		}
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			li {
				margin: 0 15px;
			}
			.icon {
				margin-right: 5px;
			}
			.label {
			}
		}

		// assortiment
		.discount-price {
			.amount {
				font-size: $font-size-lg;
			}
			.suffix {
				font-size: $font-size-sm;
			}
		}
		.price {
			.amount {
				font-size: $font-size-lg;
			}
			.suffix {
				font-size: $font-size-sm;
			}
		}
		.former-price {
			text-decoration: line-through;
			.amount {
				font-size: $font-size-sm;
			}
			.suffix {
				font-size: $font-size-sm;
			}
		}
		.downloads {
			.label {
			}
			.file {
			}
		}
	}

	// mini-search-book
	.mini-search-book {
		position: relative;
		width: 260px;
		height: 260px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin: auto auto 0 0;
		@include media-breakpoint-down(sm) {
			margin-bottom: 30px;
		}
		padding: 15px;
		border-radius: 100%;
		background: $secondary;
		box-shadow: 0 3px 6px rgba($black, 0.15);
		&::before {
			content: "";
			z-index: -1;
			position: absolute;
			left: 60%;
			bottom: 70%;
			display: flex;
			width: 180px;
			height: 133px;
			background: url("/images/camper.svg") no-repeat;
			background-size: cover;
			@include media-breakpoint-up(md) {
				left: 50%;
				bottom: 50%;
				width: 220px;
				height: 163px;
			}
			@include media-breakpoint-up(lg) {
				width: 282px;
				height: 209px;
			}
		}
		.title {
			margin-bottom: 1rem;
			color: $white;
			font-size: 20px;
			font-weight: 400;
			line-height: 1.2;
		}
		.btn {
			height: 48px;
		}
		/*.tommy-zeb-wrapper {
			float: none;
			* {
				float: none !important;
				font-family: unset !important;
			}
			&.tommy-zeb-wrapper-mini * {
				display: initial;
			}
			.tommy-zeb-left {
				display: flex;
				align-items: center;
				flex-direction: column;
				text-align: center;
				width: 200px;
				margin: auto;
				padding: 0;
				background: unset;
				.tommy-zeb-person-categories,
				.tommy-zeb-accommodation-types {
					display: none;
				}
				.tommy-zeb-date {
					h2 {
						float: none;
						display: none;
					}
					.tommy-zeb-period {
						label {
							font-size: 14px;
							font-family: $font-spline-sans !important;
						}
						input {
							width: 100%;
							margin: 0;
							padding: 10px;
							border-radius: 6px;
							box-shadow: 0 3px 6px rgba($black, 0.15);
							font-size: 14px;
							font-weight: 700;
							font-family: $font-spline-sans !important;
						}
						.tommy-zeb-date-begin-choice,
						.tommy-zeb-date-end-choice {
							display: block;
							position: relative;
							&::after {
								content: '\f073';
								position: absolute;
								top: 0;
								right: 10px;
								bottom: 0;
								font-family: 'Font Awesome 5 Pro';
								color: $primary;
								font-size: 24px;
								font-weight: 300;
								line-height: 40px;
							}
						}
					}
				}
				.ng-isolate-scope {
					width: 100%;
				}
				.tommy-zeb-btn {
					width: 100%;
					margin: 10px 0 0 0 !important;
					padding: 12px;
					background: $primary;
					border-radius: 6px;
					box-shadow: 0 3px 6px rgba($black, 0.15);
					font-size: 18px;
					&:hover {
						background: $tertiary;
					}
				}
			}
		}
		*/
	}

	// camper-visual
	/*.camper-visual {
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		max-width: 170px;
		margin: 0 0 auto auto;
		@include media-breakpoint-down(sm) {
			img {
				margin-top: -30px;
				margin-bottom: auto;
			}
		}
		@include media-breakpoint-up(sm) {
			max-width: 220px;
		}
		@include media-breakpoint-up(lg) {
			max-width: 280px;
			margin: auto;
		}
	}*/

}
&.home {
	.lead-section {
		.container-two-columns {
			.column.one {
				@include media-breakpoint-down(sm) {
					order: 1;
				}
			}
			.column.two {
				@include media-breakpoint-down(sm) {
					order: 0;
				}
			}
		}
	}
}

/* br-dark section
	========================================================================== */
.bg-dark {
	position: relative;
	padding: 0 !important;
	background-color: $teal-green !important;
	&:not(.nobefore){
		&::before {
			background: url("/images/bg-dark.svg") no-repeat top/100%;
			content: "";
			position: absolute;
			width: 100%;
			height: 165px;
			top: -28px;
			right: 0;
			@include media-breakpoint-up(sm) {
				top: -70px;
			}
			@include media-breakpoint-up(xl) {
				top: -125px;
			}
		}
	}
	&::after {
		background: url("/images/banner-bottom.svg") no-repeat top/100%;
		content: "";
		position: absolute;
		width: 100%;
		height: 105px;
		bottom: -90px;
		@include media-breakpoint-up(sm) {
			bottom: -70px;
			height: 100px;
		}
		@include media-breakpoint-up(lg) {
			bottom: -60px;
		}
		@include media-breakpoint-up(xl) {
			bottom: -40px;
		}
		@media screen and(min-width:1680px) {
			bottom: -25px;
		}
	}
	.container-one-column {
		.container-holder {
			.column {
				.image-collection {
					.grid-items {
						margin-top: -50px;
						@include media-breakpoint-up(sm) {
							margin-top: -10px;
						}
						.item {
							@include media-breakpoint-up(lg) {
								@include make-col(4);
							}
							.card {
								border: 0;
								border-radius: 0;
							}
						}
					}
				}
				div.page-block.link {
					display: flex;
					justify-content: center;
					a {
						position: relative;
						padding: 15px 25px;
						transform: translateY(30px);
						z-index: 2;
						@include media-breakpoint-up(sm) {
							padding: 15px 30px;
						}
						@include media-breakpoint-up(lg) {
							transform: translateY(15px);
						}
					}
				}
			}
		}
	}
	.container-two-columns {
		padding: 50px 15px 0;
		@include media-breakpoint-up(sm) {
			padding: 50px 15px;
		}
		.container-holder {
			.column {
				&.one,
				&.two {
					.title {
						h2 {
							font-size: $h3-font-size;
							color: $Fantasy;
						}
					}
					.page-block {
						.custom-list {
							list-style: none;
							padding-left: 10px;
							li {
								font-size: $font-size-base_sm;
								font-family: $font-spline-sans;
								font-weight: $font-weight-bold;
								margin-bottom: 10px;
								position: relative;
								&::before {
									content: "\f14a";
									position: absolute;
									top: -10%;
									left: -10px;
									font-family: $font-awesome;
									font-size: $h5-font-size;
									font-weight: $font-weight-base;
								}
							}
						}
					}
				}
				&.two {
				}
			}
		}
	}
}

/* 	content-section
    ========================================================================== */
&.home {
	.content-blocks, .content-section ,.footer-top {
		background-color: $Fantasy;
		@include media-breakpoint-up(sm) {
			background-color: transparent;
		}
	}
	.content-section {
		margin-top: 50px;
		@include media-breakpoint-up(sm) {
			margin-top: 0;
		}
		.container-two-columns {
			.container-holder {
				.column {
					&.one {
						order: 2;
						@include media-breakpoint-up(sm) {
							order: 1;
						}
					}
					&.two {
						order: 1;
						@include media-breakpoint-up(sm) {
							order: 2;
						}
					}
				}
			}
		}
	}
}
.content-section {
	padding: 100px 0;
		@include media-breakpoint-down(md) {
			padding:30px 0;
		}
	.container-two-columns {
		.container-holder {
			.column {
				&.one,
				&.two {
					.title {
						h5 {
							font-size: $font-size-base * 1.125 !important;
							color: $blue;
						}
						h1 {
							color: $teal-green;
						}
					}
					.wysiwyg {
						p {
							font-size: $font-size-base_sm;
							font-family: $font-spline-sans;
							color: $dark-slate-gray;
							margin-top: 20px;
							a {
								font-size: $font-size-base_sm;
								font-family: $font-spline-sans;
								font-weight: $font-weight-bold;
								color: $light-blue;
							}
						}
					}
				}
			}
		}
	}
}
/* 	content-section
    ========================================================================== */
.content-blocks {
	.container-one-column {
		.container-holder {
			.column {
				.grid {
					&-items {
						.item {
							@include media-breakpoint-up(lg) {
								@include make-col(6);
							}
							.card {
								border: 0;
								border-radius: 0;
								.card-img-overlay {
									padding: 30px;
									border-radius:0;
									.card-caption {
										.card-title {
											text-shadow: none;
											max-width: 500px;
											font-size: $font-size-base * 1.25;
											@include media-breakpoint-up(sm) {
												font-size: $font-size-base * 1.875;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
/* 	bundle-overview
    ========================================================================== */
&.bundle-overview,
&.bundle-detail,
&.bundle-category {
	.lead-section {
		background: $Fantasy !important;
		padding: 100px 0 40px;
		position: relative;
		.container-one-column {
			.container-holder {
				max-width: none;
			}
		}
	}
}
/* 	bundle-overview-section
    ========================================================================== */
.bundle-overview-section {
	margin: 0;
	background: $Fantasy;
}

/* 	bundle-detail-section
    ========================================================================== */
&.bundle-detail,
&.bundle-category {
	.lead-section {
		padding: 100px 0;
		@include media-breakpoint-up(xl) {
			padding: 100px 0 200px;
		}
	}
	.content-section {
		padding: 50px 0;
		@include media-breakpoint-up(sm) {
			padding: 100px 0;
		}
	}
}
.bundle-detail-section,
.gallery-section {
	position: relative;
	background-color: $teal-green;
	padding: 100px 0;

	&::before {
		background: url("/images/bg-dark.svg") no-repeat top/100%;
		content: "";
		position: absolute;
		width: 100%;
		height: 75px;
		top: -26px;
		z-index: 1;
		right: 0;
		@include media-breakpoint-up(sm) {
			height: 110px;
			top: -70px;
		}
		@include media-breakpoint-up(xl) {
			top: -120px;
			height: 135px;
		}
	}
	.container-two-columns {
		.container-holder {
			.column {
				h1, h2, h3, h4, h5, h6 {
					color: $white;
				}
				p {
					color: $white;
					font-size: $font-size-base_sm;
					font-family: $font-spline-sans;
				}
			}
		}
	}
}
&.bundle-category {
	.bundle-overview-section {
		padding-top: 100px;
	}
}
&.theme-demo {
	.lead-section {
		background-color: $Fantasy;
		padding: 100px 0;
		.container-one-column {
			.container-holder {
				.column {
					h5 {
						font-size: $font-size-base * 1.125 !important;
						color: $blue;
					}
					h1 {
						color: $teal-green;
					}
					p {
						font-size: $font-size-base_sm;
						font-family: $font-spline-sans;
						color: $dark-slate-gray;
						margin-top: 20px;
					}
				}
			}
		}
	}
	.content-section {
		background-color: $Fantasy;
		padding-bottom: 200px;
		.container-two-columns {
			.container-holder {
				.column {
					&.one {
						h1,
						h2,
						h3 {
							color: $teal-green;
						}
						p {
							font-size: $font-size-base_sm;
							font-family: $font-spline-sans;
							color: $dark-slate-gray;
							em {
								font-style: normal;
							}
						}
					}
					&.two {
						@extend .list-type;
					}
				}
			}
		}
	}
	.lead-section.bg-dark {
		padding: 100px 0 0 !important;
		&::after {
			content: none;
		}
		.container-one-column {
			.container-holder {
				.column {
					h1,
					h5,
					p {
						color: $white;
					}
				}
			}
		}
	}
	.content-section.bg-dark {
		&::before {
			content: none;
		}
		padding-bottom: 100px !important;
		.container-two-columns {
			.container-holder {
				.column {
					&.one {
						h1,
						h2,
						h3,
						p {
							color: $white;
						}
					}
					&.two {
						@extend .list-type;
						ul,
						ol {
							li {
								opacity: 1 !important;
								&::before {
									color: $white;
								}
							}
						}
						p {
							a {
								color: $white;
							}
						}
						.btn-secondary,
						.btn-outline-secondary {
							@extend .btn-secondary-transparent;
						}
						.btn-outline-primary {
							@extend .btn-outline-primary-transparent;
						}
					}
				}
			}
		}
	}
}

body.default .card.card-overlay .card-img-overlay{
	background:transparent;
}

/* 	booking-section
    ========================================================================== */
.booking-section {
	padding-bottom: 6vh;
	background-color: $teal-green;
	.column {
		> h2 {
			display: none;
		}
	}
}
