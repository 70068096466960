&.faq-overview {
	.faq-overview {
		.faq-category {
			.faq-items {
				display: block;

				.faq-item {
					.faq-answer {
						display: block;
					}
				}
			}
		}
	}
}
