// colors

/*  font
       ========================================================================== */
@font-face {
    font-family: "Spline Sans";
    src: url("/fonts/SplineSans-Regular.ttf");
    font-weight: 400;
}
@font-face {
    font-family: "Spline Sans";
    src: url("/fonts/SplineSans-Medium.ttf");
    font-weight: 500;
}
@font-face {
    font-family: "Spline Sans";
    src: url("/fonts/SplineSans-Bold.ttf");
    font-weight: 700;
}

$font-spline-sans: "Spline Sans", sans-serif;

$font-awesome: "Font Awesome 5 Pro";

$font-weight-light: 300;
$font-weight-base: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-size-base: 1rem !default; //16px
$font-size-base_sm: 0.875rem !default; //14px
$font-size-base_xs: 0.813rem !default; //13px
$font-size-base_xxs: 0.75rem !default; //12px

$h1-font-size: $font-size-base * 3 !default;
$h2-font-size: $font-size-base * 2.25 !default;
$h3-font-size: $font-size-base * 1.875 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.125 !default;
$h6-font-size: $font-size-base_sm !default;
