.header {
	.container-fluid {
		.container-holder {
			.column {
				background: $blue !important;
				padding: 0;
				@include media-breakpoint-up(xl) {
					padding: 14px 35px;
				}

				.logo {
					img {
						@media screen and (max-width: 540px) {
							max-height: 30px;
						}
						@media screen and (max-width: 400px) {
							max-height: 18px;
						}
						@include media-breakpoint-up(xl) {
							width: 165px;
							height: 61px;
						}
					}
				}

				.navbar-collapse {
					.navbar-nav {
						margin: auto;

						.nav-item {
							position: relative;

							.nav-link {
								color: $white;
								font-size: $font-size-base_sm;
								font-family: $font-spline-sans;
								font-weight: $font-weight-bold;

								&:hover {
									color: $light-blue;
								}
							}

							&.active:not(.dropdown) {
								&::after {
									display: block;
									content: "";
									border-bottom: 4px solid $light-blue;
									width: 100%;
									content: "";
									position: absolute;
									bottom: 0;
									@media(max-width: 767px) {
										bottom: 0;
									}
								}
							}

							&.dropdown {
								.dropdown-toggle {
									position: relative;
									@include media-breakpoint-down(lg) {
										&::after {
											position: absolute;
											right: -5px;
											transform: translateY(-50%);
											top: 50%;
											display: block;
											margin-right: 15px;
											content: "";
											font-family: $font-awesome;
											font-size: $font-size-base_xs;
											font-weight: 700;
											border: none;
										}
									}
								}

								.dropdown-menu {
									background: $blue;
									@include media-breakpoint-down(lg) {
										position: static !important;
										transform: none !important;
										min-width: 100%;
										border: none;
										background-color: transparent;
										box-shadow: none;
										padding: 0;
										margin: 0;
									}

									.nav-item {
										color: $white;

										&:hover {
											background: rgba(255, 255, 255, 0.1);
											color: $light-blue;
										}
									}
								}

								&.active {
									@media(max-width: 992px) {
										background: rgba(255, 255, 255, 0.1);
									}

									.dropdown-toggle {
									}

									> a {
										color: $light-blue;
									}
								}
							}
						}
					}
				}

				.global_header_button {
					li {
						a {
							background: $light-blue;
							color: $white;
							font-size: $font-size-base_xxs;
							text-decoration: none;
							width: 96px;
							height: 64px;
							padding: 10px;
							border-radius: 5px;
							display: flex;
							align-items: flex-end;
							position: absolute;
							top: 20px;
							margin-left: 30px;
							@include media-breakpoint-up(sm) {
								font-size: $font-size-base;
								width: 114px;
								height: 117px;
								margin-left: 0;
							}
						}
					}
				}

				.global_social {
					li {
						a {
							margin-left: 130px;
							color: $white;
							padding-top: 12px;
							display: block;
							width: 26px;
							height: 26px;

							i {
								margin-top: -12px;
								color: $dark-red;
								background: $white;
								width: 26px;
								height: 26px;
								border-radius: 50%;
								display: flex;
								align-items: center;
								justify-content: center;

								&::before {
									content: "\f39e" !important;
								}
							}

							span {
								display: none;
							}

							&:hover {
								text-decoration: none;

								i {
									color: $white;
									background-color: $dark-red;
								}
							}
						}
					}
				}

				.site-switcher {
					margin-left: 0;

					.navbar-nav {
						.nav-item {
							.nav-link {
								padding: 0 0.5rem;

								img {
									border: 1px solid white;
									border-radius: 50%;
								}

								&:hover {
									opacity: 0.7;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-left: 0;
									@include media-breakpoint-up(sm) {
										padding-left: 0.5rem;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// responsive custom styling
	.page-block.menu-toggle {
		min-width: 0;
		display: flex;
		background-color: $light-blue;

		.navbar-toggler {
			&-icon {
				color: $white;
			}
		}
	}

	.page-block.logo {
		min-width: 0;
		margin: 0;
	}

	.page-block.site-switcher {
		margin: 0;
		flex-grow: 1;
	}

	// responsive mobile header order.
	.page-block.menu-toggle {
		order: 0;
	}

	.page-block.logo {
		order: 1;
		margin-left: 20px;
		display: flex;
		flex-grow: 1;
		@include media-breakpoint-down(xs) {
			flex-grow: 0;
		}
	}

	div.global_header_button {
		order: 2;
		flex-grow: 1;
		@include media-breakpoint-up(xl) {
			order: unset;
			flex-grow: 0;
		}
	}

	.global_social {
		order: 3;
		margin-bottom: 0;
		@include media-breakpoint-up(xl) {
			order: unset;
		}
	}

	.page-block.site-switcher {
		order: 4;
	}

	.page-block.menu {
		order: 5;
		margin-top: 55px;
		@include media-breakpoint-up(xl) {
			margin-top: 0;
		}
	}

	// Desktop resets
	@include media-breakpoint-up(xl) {
		.page-block.menu-toggle {
			order: unset;
		}
		.page-block.logo {
			order: unset;
		}
		.page-block.site-switcher {
			order: unset;
		}
		.page-block.menu {
			order: unset;
		}

		.page-block.site-switcher {
			display: flex;
			justify-content: flex-end;
			margin-left: 25px;
			flex-grow: 0;
		}

		.page-block.menu-toggle {
			margin: 0 0 0 auto;
		}
	}
}
