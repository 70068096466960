/* 	bundle-overview-section
    ========================================================================== */
    .bundle-overview-section {
       .container-one-column {
            .container-holder {
                .column {
                    .grid {
                        &-items {
                            .item {
                                @include media-breakpoint-up(lg) {
                                    @include make-col(4);
                                }
                                .card {
                                    border: 0;
                                    border-radius: 0;
                                    &-body {
                                        padding: 20px;
                                        .card-caption {
                                            display: flex;
                                            flex-direction: column;
                                            .card-title-link {
                                                order: 2;
                                                .card-title {
                                                    font-size: $font-size-base * 1.5;
                                                    &:hover {
                                                        opacity: 0.7;
                                                    }
                                                    @include media-breakpoint-up(sm) {
                                                        font-size: $font-size-base * 1.875;
                                                        color: $blue;
                                                    }
                                                }
                                            }
                                            .card-subtitle,
                                            .card-description, .card-prices {
                                                font-size: $font-size-base_sm;
                                                font-family: $font-spline-sans;
                                                color: $dark-slate-gray;
                                            }
                                            .card-subtitle {
                                                order: 1;
                                            }
                                            .card-description {
                                                order: 3;
                                                margin-top: 10px;
                                            }
                                        }
                                        .card-buttons {
                                            .card-btn {
                                                display: inline-block;
                                                font-size: $font-size-base_sm !important;
                                                background: transparent;
                                                border: none;
                                                box-shadow: none;
                                                color: $light-blue;
                                                padding: 0 0 20px;
                                                &::after {
                                                    content: '';
                                                    border-bottom: 2px solid $light-blue;
                                                    width: 75px;
                                                    display: block;
                                                } 
                                            }
                                        }
                                    }
                                }
                                .card-img-overlay{
                                    background: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .card {
        &.card-assortiment {
            .card-caption {
                .card-prices {
                    font-weight: $font-weight-base;
                    font-size: $font-size-base_sm;
                }
            }
        }
    }




    .page-block{
        &.image-collection{
            .grid-items{
                .item{
                    .card-overlay{
                        .card-img-overlay{
                            background:none;
                        }
                    }
                }
            }
        }
    }