.eyecatcher {
	position: relative;
	margin-top: 0;
	@include media-breakpoint-up(xl) {
		margin-top: -20px;
	}

	&::after {
		background: url(/images/banner-bottom.svg) no-repeat top/100%;
		content: "";
		position: absolute;
		width: 100%;
		height: 100px;
		bottom: -85px;
		z-index: 1;
		right: 0;
		@include media-breakpoint-up(sm) {

		}
		@include media-breakpoint-up(xl) {
			bottom: -25px;
		}
	}

	&-owl-carousel {
		.owl-stage-outer {
			.owl-stage {
				.owl-item {
					.item {
						.owl-container {
							padding-right: 30px;
							padding-left: 30px;
							@include media-breakpoint-up(sm) {
								padding-right: 15px;
								padding-left: 15px;
							}

							.owl-caption {
								position: absolute;
								bottom: 80px;
								text-align: left;

								.owl-title {
									font-size: $font-size-base * 2.25;
									font-weight: $font-weight-base;
									@media screen and (max-width: 320px) {
										font-size: $h4-font-size;
									}
									@include media-breakpoint-up(lg) {
										font-size: $font-size-base * 3.125;
									}
								}

								.owl-subtitle {
									font-weight: $font-weight-base;
								}

								.owl-description {
									font-family: $font-spline-sans;
									font-size: $font-size-base_sm;
									font-weight: $font-weight-medium;

									.custom-list-extra {
										display: flex;
										flex-wrap: wrap;
										list-style: none;
										padding-left: 25px;
										margin-top: 30px;

										li {
											margin-right: 50px;
											position: relative;
											font-family: $font-spline-sans;
											font-size: $font-size-base / 1.45;
											font-weight: $font-weight-medium;
											@include media-breakpoint-up(lg) {
												font-size: $font-size-base_sm;
											}

											&::before {
												content: "\f14a";
												position: absolute;
												left: -20px;
												font-family: $font-awesome;
											}
										}
									}
								}

								.owl-btn {
								}
							}
						}
					}
				}
			}
		}

		.owl-dots,
		.owl-nav {
			display: none;
		}
	}
}



