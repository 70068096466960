/*  base
    ========================================================================== */   

    /* headings */
    h1 {
      font-size: 36px;
      @media screen and (max-width:320px) {
        font-size: $h4-font-size;
      }
      @include media-breakpoint-up(xl) {
			  font-size: 48px;
      }
    }
    h2 {
      font-size: 30px;
      @include media-breakpoint-up(xl) {
        font-size: 36px;
      }
    }
   
    h1, h2,h3,h4,h5,h6 {
      font-weight: $font-weight-base !important;
    }

    /* img */
    img {
      max-width: 100%;
      height: auto;
    }

    /* transition */
    a,
    .btn,
    .owl-btn,
    .card-btn { transition: 0.5s !important;}

    /* form */
    .form {
      padding: 15px;
      @include media-breakpoint-up(xl) {
        padding: 30px;
      }
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
    }
.list-type {
  h4 {
    margin-bottom: 15px;
  }
  ul,.custom-list-extra {
    list-style: none;
    padding-left: 20px;
    li {
      position: relative;
      font-size: $font-size-base_sm;
      font-family: $font-spline-sans;
      margin-bottom: 5px;
      &::before {
        content: "\f111";
        position: absolute;
        top: 20%;
        left: -18px;
        font-family: $font-awesome;
        font-size: $font-size-base / 2;
        color: $teal-green;
        font-weight: $font-weight-bold;
        opacity: 0.8;
      }
    }
  }
  ol {
    padding-left: 0;
    list-style: none;
    counter-reset: li;
    li {
      font-size: $font-size-base_sm;
      font-family: $font-spline-sans;
      &::before {
        counter-increment: li;
        content: counter(li) ".";
        margin-right: 10px;
        margin-bottom: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $teal-green;
        font-weight: $font-weight-bold;
        opacity: 0.8;
      }
    }
  }
  .custom-list {
    padding-left: 0 !important;
    li {
      &::before {
        content: "\f00c" !important;
        font-family: $font-awesome;
        font-size: $font-size-base_sm !important;
        left: 0 !important;
        top: 10%;
      }
    }
  }
  .custom-list-extra {
    margin-bottom: 30px;
  }
  p {
    a {
      color: $light-blue;
      font-size: $font-size-base_sm;
      font-family: $font-spline-sans;
    }
  }
  .btn-primary {
    @extend .btn-primary;
    margin-top: 20px;
  }
}
